import { Injectable } from '@angular/core'
import { allConfigs, mockClientData, mockConfigData, mockKeysData } from '../mock-data/config.mock-data'
import { IConfig } from './config.service'
import { browseByCategoryData, buildAssets } from '../mock-data/assets.mock-data'
import { ISearchResults } from './search.service'
import { IPagination } from './api-request.service'
import { IClient } from './client.service'
import { currencyRates } from '../mock-data/currency.mock-data'
import { ICurrencyRateData } from '../interfaces/currency.interface'
import { IAssetCategoriesResponse, IAssetCategory, IAssetsResponse } from '../interfaces/asset.interface'
import { IKeys } from './keys.service'
import { IApiAllConfigsResponse } from './init-data.service'

@Injectable()
export class MockService {
  getMockConfig(): IConfig {
    return mockConfigData
  }

  getMockClient(): IClient {
    return mockClientData
  }

  getMockKeys(): IKeys {
    return mockKeysData
  }

  getAllConfigs(): IApiAllConfigsResponse {
    return allConfigs
  }

  getSearchResults(term: string): ISearchResults {
    if (!term || term.length < 3) {
      return {
        filter_make: [],
        filter_model: [],
        filter_category: [],
        filter_sub_category: [],
        filter_branch_state: [],
        branch_city: [],
        filter_equipment_number: [],
      }
    }

    return {
      filter_make: [{ value: 'Caterpillar', count: 1 }],
      filter_model: [{ value: 'D6', count: 1 }],
      filter_category: [{ value: 'Dozer', count: 1 }],
      filter_sub_category: [{ value: 'Crawler', count: 1 }],
      filter_branch_state: [{ value: 'CA', count: 1 }],
      branch_city: [{ value: 'Los Angeles', count: 1 }],
      filter_equipment_number: [{ value: 'display_eq_number_0', value_id: 'eq_number_0', count: 1 }],
    }
  }

  getAssetCategories(pagination: IPagination): IAssetCategoriesResponse {
    let data: IAssetCategory[] = []
    switch (pagination?.field) {
      case 'alpha':
        if (pagination.direction === 'desc') {
          data = browseByCategoryData.sort((a, b) => a.category.localeCompare(b.category))
        } else {
          data = browseByCategoryData.sort((a, b) => -1 * a.category.localeCompare(b.category))
        }
        break
      case 'quantity':
        if (pagination.direction === 'asc') {
          data = browseByCategoryData.sort((a, b) => a.quantity - b.quantity)
        } else {
          data = browseByCategoryData.sort((a, b) => b.quantity - a.quantity)
        }
        break
    }
    return {
      data: data.slice().splice(0, pagination.size),
      pagination: {
        total_items: pagination.size,
        total_pages: 1,
        direction: pagination.direction,
        field: pagination.field,
        index: 0,
        size: pagination.size,
      },
    }
  }

  getAssets(pagination: IPagination = {
    direction: 'asc',
    field: 'equipment_number',
    index: 0,
    size: 10
  }): IAssetsResponse {
    return {
      data: buildAssets(pagination.size),
      pagination: {
        total_items: pagination.size,
        total_pages: 1,
        direction: pagination.direction,
        field: pagination.field,
        index: 0,
        size: pagination.size,
      },
    }
  }

  getCurrencyRates(): ICurrencyRateData[] {
    return currencyRates
  }

  submitContactForm() {
    return {
      success: false,
    }
  }
}
